<script setup>
import { defineProps, computed } from 'vue'
import Icon from '@/components/Icon/index.vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})

const valueColumn = computed(() => {
  return props.data[props.column]
})

</script>

<template>
  <div class="flex flex-wrap items-center">
    <span class="mr-2">{{ valueColumn }}</span>
    <Icon
      v-if="props.data.Warning"
      icon="mdi:alert"
      class="text-yellow-500"
      size="1.5em"
      v-tippy="{ content: `${props.data.Warning}` }"
    />
  </div>
</template>

<style scoped>

</style>
