<script setup>
import { ref, defineExpose } from 'vue'
import Icon from '@/components/Icon/index.vue'
import { FilterMatchMode } from 'primevue/api'

const displayModal = ref(false)
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
const infoWarnings = ref({})

// Funcion para abrir el modal
const openModal = async (info) => {
  console.log('Info modal warnings --> ', info)
  displayModal.value = true
  infoWarnings.value = info
}

// Funcion para cerrar el modal
const closeModal = async () => {
  displayModal.value = false
}

// Expose
defineExpose({
  openModal
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    modal
    maximizable
    dismissableMask
    @hide="closeModal"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '60vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-yellow-600">
        <i class="pi pi-info-circle mr-2 text-xl"></i>
        <h4>Información Notificaciones</h4>
      </div>
    </template>

    <!-- Body del modal -->
    <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 px-2">
      <div class="col-span-1 sm:col-span-12 md:col-span-12">
        <p class="mb-2">
          A continuación puede ver el listado de notificaciones.
        </p>

        <!-- Listado de errores -->
        <DataTable
          showGridlines
          ref="dt"
          :value="infoWarnings.data"
          class="p-datatable-sm text-sm"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          sortField="id"
          :sortOrder="-1"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5,10,25,50]"
          currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
              <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg text-yellow-800">Listado de notificaciones
              </h3>
              <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
            </div>
          </template>

          <!-- Columnas -->
          <template #empty>
            <div class="flex flex-col items-center justify-center" style="height: 400px">
              <Icon icon="flat-color-icons:warning" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
              <span class="text-gray-400 text-2xl">No se encontraron registros</span>
              <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
            </div>
          </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column v-for="column in infoWarnings.columns" :key="column.field" :field="column.field" :header="column.header" sortable />
        </DataTable>
      </div>
    </div>
  </Dialog>
</template>

<style scoped>

</style>
