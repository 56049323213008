<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import * as XLSX from 'xlsx'
import { useToast } from 'primevue/usetoast'
import FacturasCapitaValorService from '@/apps/pharmasan/facturacion/capita/services/facturasCapitaValor.service'
import FilePond from '@/apps/pharmasan/facturacion/capita/views/cargaOvFactura/components/uploadFilePond/index.vue'
import ModalWarnings from '@/apps/pharmasan/facturacion/capita/views/cargaOvFactura/components/modalWarnings.vue'
import Swal from 'sweetalert2'
import Icon from '@/components/Icon/index.vue'
import { FilterMatchMode } from 'primevue/api'
import ColumnTemplateDefault from '@/apps/pharmasan/facturacion/capita/views/cargaOvFactura/components/columnTemplateDefault.vue'
import ColumnTemplateDocNumOv from '@/apps/pharmasan/facturacion/capita/views/cargaOvFactura/components/columnTemplateDocNumOv.vue'

const router = useRouter()
const route = useRoute()
const toast = useToast()
const _FacturasCapitaValorService = ref(new FacturasCapitaValorService())
const attachFile = ref(null)
const dataRelacionOvFacturaSave = ref([])
const listErrors = ref([])
const listErrorsColumns = ref([])
const listResults = ref([])
const listColumns = ref([])
const listColumnsWarning = ref([])
const listWarnings = ref([])
const DocNumFactura = ref(null)
const refModalWarnings = ref(null)
const columnComponents = reactive({
  DocNumOv: ColumnTemplateDocNumOv
})
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Funcion para abrir el modal de warnings
const openModalWarnings = (info) => {
  refModalWarnings.value.openModal(info)
}

// Buscar la factura
const getFacturaCapitaValor = async (DocNum) => {
  try {
    const { data } = await _FacturasCapitaValorService.value.searchFactura(DocNum)

    if (data.total === 0 || Object.keys(data.result).length === 0) {
      Swal.fire({
        title: 'Validación',
        html: `
          <p class="mb-2">No se encontró la factura #${DocNum} por alguna de las siguientes razones:</p>
          <ul class="text-sm">
            <li>No existe</li>
            <li>No es Capita</li>
            <li>No es factura electronica</li>
            <li>El total es menor o igual a 0</li>
          </ul>
        `,
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false
      }).then(() => {
        router.push({ name: 'pharmasan.ventas.facturacion.capita-valor.listado' })
      })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Funcion para descargar plantilla excel
const handleDownloadXls = async () => {
  await _FacturasCapitaValorService.value.downloadTemplate({ DocNumFactura: DocNumFactura.value }).then((url) => {
    window.open(url, '_blank')
  }).catch((err) => {
    console.error(err.message)
    console.error(err.response.data.message)
  })
}

/* Capturar el adjunto de filepond */
const onCaptureFile = (files) => {
  console.log('files --> ', files)
  attachFile.value = files
}

/* Limpiando el attach cuando se remueve o reemplaza el archivo */
const onRemoveFile = (files) => {
  clearInfo()
}

// Clear
const clearInfo = () => {
  attachFile.value = null
  listErrors.value = []
  listErrorsColumns.value = []
  listResults.value = []
  listColumns.value = []
  listColumnsWarning.value = []
  listWarnings.value = []
  dataRelacionOvFacturaSave.value = []
}

// Funcion para convertir columnas a letras
const columnToLetter = (column) => {
  let temp
  let letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

// Funcion para cargar archivo excel
const handleFileUpload = async () => {
  if (attachFile.value) {
    const reader = new FileReader()

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const dataExcel = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true })

      const keys = dataExcel[0]
      const dataRelacionOvFactura = dataExcel.slice(1).map((values, rowIndex) => {
        const obj = {}
        keys.forEach((key, columnIndex) => {
          const columnLetter = columnToLetter(columnIndex + 1)
          obj[key] = {
            value: values[columnIndex] !== undefined ? values[columnIndex] : null,
            row: rowIndex + 2,
            column: columnLetter
          }
        })
        return obj
      })

      const output = {
        columns: keys,
        data: dataRelacionOvFactura
      }
      const outputDataExcel = JSON.stringify(output)

      await _FacturasCapitaValorService.value.validateTemplate({ data: outputDataExcel, DocNumFactura: DocNumFactura.value }).then((res) => {
        console.log('response --> ', res)
        if (res.data.success) {
          Swal.fire({
            title: '¡Éxito!',
            icon: 'success',
            html: `
              <div class="flex flex-col">
                <span class="text-base">Las validaciones del archivo excel han sido correctas</span>
                <span class="text-sm">A continuación se visualizará el listado a importar</span>
              </div>
            `,
            confirmButtonText: 'Aceptar'
          })

          // Asignar los valores al listado
          listResults.value = res.data.dataSave
          listColumns.value = res.data.columns
          dataRelacionOvFacturaSave.value = res.data.dataSave
          listErrors.value = []
          listErrorsColumns.value = []
          listColumnsWarning.value = res.data.columnsWarnings
          listWarnings.value = res.data.dataWarnings
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Validación incorrecta',
            text: 'Los campos del archivo excel no son correctos',
            html: '<span style="font-size: 13px;">Visualizar los errores</span>'
          }).then(() => {
            listErrors.value = res.data.dataErrors
            listErrorsColumns.value = res.data.columns
            dataRelacionOvFacturaSave.value = []
            listColumns.value = []
            listResults.value = []
            listColumnsWarning.value = []
            listWarnings.value = []
          })
        }
      }).catch((err) => {
        console.error(err.message)
        console.error(err.response.data.message)
      })
    }
    reader.readAsArrayBuffer(attachFile.value)
  } else {
    toast.add({ severity: 'error', summary: 'Validación', detail: 'Por favor seleccione un archivo', life: 3000 })
  }
}

// Funcion para importar registros
const onSubmit = async () => {
  if (dataRelacionOvFacturaSave.value.length > 0) {
    Swal.fire({
      icon: 'info',
      title: '¿Esta seguro/a?',
      html: `
        <span class="text-base">¿Desea importar las OVs a la factura #${DocNumFactura.value}?</span>
      `,
      showConfirmButton: true,
      confirmButtonColor: '#24963E',
      confirmButtonText: 'Si, Importar!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, cancelar!'
    }).then(async (resp) => {
      if (resp.isConfirmed) {
        try {
          const response = await _FacturasCapitaValorService.value.saveOvsFactura({ data: JSON.stringify(dataRelacionOvFacturaSave.value) })
          console.log('response --> ', response)
          if (response.status === 200 && response.data.success) {
            Swal.fire({
              icon: 'success',
              title: '¡Éxito!',
              text: 'Las OVs han sido asociadas correctamente a la factura',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              clearInfo()
              router.push({ name: 'pharmasan.ventas.facturacion.capita-valor.listado' })
            })
          } else {
            console.log('Error al importar las OVs a la factura: ', response)
            await Swal.fire({
              icon: 'error',
              title: 'Error',
              html: `Hubo un error al importar las OVs <br> <span style="font-size: 13px;">${response.data.message ?? ''}</span> <br> <span style="font-size: 13px;">${response.data.details ?? response.data.error}</span>`
            })
          }
        } catch (error) {
          console.log('Error al importar las OVs a la factura: ', error.response)
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            html: `Hubo un error al importar las OVs <br> <span style="font-size: 13px;">${error.response.data.message ?? ''}</span> <br> <span style="font-size: 13px;">${error.response.data.details ?? ''}</span>`
          })
        }
      }
    })
  } else {
    toast.add({ severity: 'error', summary: 'Validación', detail: 'No hay OVs para importar', life: 3000 })
  }
}

// Funcion para mostrar el componente según la columna en la tabla
const showComponentColumn = (column) => {
  // Si la columna existe en el objeto columnComponents, retorna el componente correspondiente
  if (columnComponents[column]) {
    return columnComponents[column]
  }

  // Si la columna no se encuentra en el objeto, retorna el componente por defecto
  return ColumnTemplateDefault
}

// Hook
onMounted(async () => {
  clearInfo()

  const { DocNum } = route.params
  if (DocNum) {
    await getFacturaCapitaValor(DocNum)
    DocNumFactura.value = DocNum
  }
})
</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <h2 class="text-lg font-bold truncate mr-5">Cargue Masivo OV a la factura <span class="text-blue-800">#{{ DocNumFactura }}</span></h2>
    </template>
    <template #end>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid gap-4 grid-cols-12 mt-5">
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Información Plantilla</h2>
        </div>
        <div class="p-4 h-36 box flex items-center justify-center" style="height: 11rem; text-align:center;">
          <span class="text-sm">Visualizar validaciones que realiza el sistema en la plantilla excel con las OVs, haz clic
            <a
              class="no-underline hover:underline text-indigo-600 cursor-pointer font-bold"
              v-tippy="{
                content: `
                  <div class='flex flex-col'>
                    <p class='text-sm font-semibold'>Validaciones:</p>
                    <span class=''>1. Que exista.</span>
                    <span class=''>2. Que no se encuentre registrada con otra factura capita valor.</span>
                    <span class=''>3. Que no esté cancelada.</span>
                    <span class=''>4. Que la modalidad de contrato y motivo de autorización sea CAPITA.</span>
                    <span class=''>5. Que pertenezca al cliente de la factura.</span>
                    <span class=''>6. Que si la ov tiene una factura y la factura tiene nota credito se puede asignar nuevamente.</span>
                  </div>
                `,
                trigger: 'click',
              }"
            >aquí</a>
          </span>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Descargar Plantilla Excel</h2>
        </div>
        <div class="p-4 h-36 box flex items-center justify-center" style="height: 11rem;">
          <Button label="Descargar Plantilla Excel" @click="handleDownloadXls()" icon="pi pi-table" class="p-button-warning p-button-sm" />
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Cargar e importar plantilla excel</h2>
        </div>
        <div class="p-4 flex flex-col" style="height: 11rem;">
          <FilePond ref="pondFile" @captureFile="onCaptureFile" @removeFile="onRemoveFile" />
          <Button
            label="Validar importación"
            icon="pi pi-upload"
            class="p-button-success p-button-sm"
            @click="handleFileUpload"
          />
        </div>

      </div>
    </div>

    <!-- Table -->
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div
          class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5"
          :class="{'bg-green-100': listResults.length > 0, 'bg-red-100': listErrors.length > 0, 'bg-yellow-100': listWarnings.length > 0}"
        >
          <h2
            class="font-medium text-base"
            :class="{'text-green-800': listResults.length > 0, 'text-red-800': listErrors.length > 0, 'text-yellow-800': listWarnings.length > 0}"
          >Información o resultados de la validación del archivo cargado.</h2>
          <!-- Botón mas filtros -->
          <Button
            v-if="listWarnings.length > 0"
            severity="warning"
            text
            size="small"
            style="height: 30px"
            @click="openModalWarnings({ data: listWarnings, columns: listColumnsWarning, title: 'Listado de notificaciones'})"
          >
            <div class="flex flex-col">
              <div class="flex flex-wrap justify-center items-center">
                <Icon icon="mdi:alert" class="text-sm" />
                <span class="ml-2 text-xs font-bold">Ver notificaciones</span>
              </div>
            </div>
          </Button>
        </div>
        <div class="p-4">
          <!-- Listado de datos a importar -->
          <DataTable
            v-if="listResults.length > 0 || listErrors.length === 0"
            ref="dt"
            :value="listResults"
            class="p-datatable-sm text-sm"
            dataKey="DocNumOv"
            scrollable
            scrollHeight="400px"
            :virtualScrollerOptions="{ itemSize: 46 }"
            :filters="filters"
            sortField="DocNumOv"
            :sortOrder="-1"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between items-center">
                <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg" :class="{'text-green-800': listResults.length > 0}">Listado de OVs a importar</h3>
                <span class="text-green-800 text-base">Total: {{ listResults.length }}</span>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>

            <!-- Columnas -->
            <template #empty>
              <div class="flex flex-col items-center justify-center" style="height: 380px">
                <Icon icon="flat-color-icons:fine-print" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
                <span class="text-gray-400 text-2xl">Esperando por la validación del archivo a importar</span>
                <span class="text-gray-300">Cargue e importe el archivo en excel para visualizar la información o los errores de validación</span>
              </div>
            </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column
              v-for="column in listColumns"
              :key="column.field"
              :field="column.field"
              :header="column.header"
              :sortable="true"
            >
              <template #body="{data}">
                <component
                  :is="showComponentColumn(column.field)"
                  :data="data"
                  :column="column.field"
                />
              </template>
            </Column>
          </DataTable>

          <!-- Listado de errores -->
          <DataTable
            v-else
            showGridlines
            ref="dt"
            :value="listErrors"
            class="p-datatable-sm text-sm"
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            sortField="id"
            :sortOrder="-1"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5,10,25,50]"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            responsiveLayout="scroll"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg text-red-800">Listado de errores
                  <span class="text-gray-500 text-xs font-semibold">(Revisa y corrige los errores listados a continuación en el excel y vuelve e intenta de nuevo realizar la validación de importación)</span>
                </h3>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>

            <!-- Columnas -->
            <template #empty>
              <div class="flex flex-col items-center justify-center" style="height: 500px">
                <Icon icon="flat-color-icons:warning" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
                <span class="text-gray-400 text-2xl">No se encontraron registros</span>
                <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
              </div>
            </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column v-for="column in listErrorsColumns" :key="column.field" :field="column.field" :header="column.header" sortable />
          </DataTable>
        </div>
      </div>
    </div>

  </div>

  <Button
    v-if="dataRelacionOvFacturaSave.length > 0"
    label="Importar Registros"
    icon="pi pi-save"
    severity="success"
    size="small"
    class="p-button-raised p-button-success floating-button p-button-rounded"
    @click="onSubmit"
  />

  <ModalWarnings ref="refModalWarnings" />
</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 1050;
}
</style>
